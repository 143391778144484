
















import Vue from 'vue';
import VerificationUtils, { Verification } from '@/utils/VerificationUtils';
import UserUtils, { User } from '@/utils/UserUtils';
import moment from 'moment';

export default Vue.extend({
  props: {
    value: {
      type: Object as () => Verification,
    },
    verification: {
      type: Function as () => any,
    },
  },

  computed: {
    internal: {
      get(): Verification {
        return VerificationUtils.generate(this.value);
      },
      set(value: any) {
        this.$emit('input', value);
      },
    },

    status: {
      get(): boolean {
        return this.internal.status === 'verified';
      },
      set(value: boolean) {
        this.verify(value);
      },
    },

    display(): string | undefined {
      const timestamp = this.internal.timestamp
        ? moment
            .utc(this.internal.timestamp)
            .local()
            .format('YYYY-MM-DD, H:mm:ss')
        : undefined;

      if (timestamp && this.user?.username) {
        return this.$i18n.t('verification.timestamp', [
          this.user?.username,
          timestamp,
        ]) as string;
      } else {
        return undefined;
      }
    },
  },

  data(): {
    state: {
      submit: boolean;
    };
    user?: User;
  } {
    return {
      state: {
        submit: false,
      },
      user: undefined,
    };
  },

  methods: {
    async verify(value: boolean) {
      try {
        this.state.submit = true;
        await this.verification(value);
      } catch (error) {
        console.error(error);
      } finally {
        this.state.submit = false;
      }
    },
  },

  watch: {
    'internal.user_id': {
      immediate: true,
      async handler(value: number) {
        if (value) {
          try {
            this.state.submit = true;
            this.user = undefined;
            this.user = await UserUtils.api.get(value);
          } catch (error) {
            console.error(error);
          } finally {
            this.state.submit = false;
          }
        }
      },
    },
  },
});
